import { Controller } from "@hotwired/stimulus"

export default class extends Controller {

    connect() {
        this.ticks = this.data.get("ticks");
        setTimeout(() => this.tick(), 1000)
    }

    tick() {
        this.ticks--;
        var formatedTicks = this.formatTickes(this.ticks);
        if (formatedTicks.startsWith("-")) {
            this.element.innerText = "00:00";
        } else {
            this.element.innerHTML = formatedTicks;
        }

        if (this.ticks > 0) {
            setTimeout(() => this.tick(), 1000);
        }
    }

    formatTickes(ticks) {
        let minutes = Math.floor(ticks / 60)
        let seconds = ticks % 60
        return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`
    }
}



