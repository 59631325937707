
import { Controller } from "@hotwired/stimulus"
import FunnelGraph from "funnel-graph-js"

export default class extends Controller {
    static values = {
        funil: Object
    }

    connect() {
        let funilValue = this.funilValue;
        var graph = new FunnelGraph({
            container: `#${this.element.id}`,
            gradientDirection: 'horizontal',
            data: funilValue.data,
            displayPercent: true,
            direction: 'horizontal',
            width: 800,
            height: 200,
        });

        graph.draw();

    }
}