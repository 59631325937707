import { Controller } from 'stimulus';
import { get } from '@rails/request.js';

export default class extends Controller {
    static targets = ['cidadesSelect']
    change(event) {
        let estado_id = event.target.selectedOptions[0].value;
        let target = this.cidadesSelectTarget.id
        document.getElementById(target).innerHTML = "Carregando..."
        get(`/estados/${estado_id}/cidades?target=${target}`, {
            responseKind: 'turbo-stream'
        });
    }
}