import { Controller } from 'stimulus';
import { post } from '@rails/request.js';

export default class extends Controller {



    mark(event) {
        let { params: { provaId, questaoId, id } } = event;
        if (!(window.confirm("Deseja prosseguir? Após clicar em Ok não será possivel retornar a esta questão."))) {
            event.preventDefault()
            event.stopImmediatePropagation()
        } else {
            post(`/prova/${provaId}/questao/${questaoId}/alternativa/${id}`, {
                responseKind: 'turbo-stream'
            });
        }
    }
}