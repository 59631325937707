import { Controller } from "@hotwired/stimulus"
import Inputmask from "inputmask";

var im = new Inputmask("(99) 99999-9999");


export default class extends Controller {
    connect() {
        im.mask(this.element);
    }
}