import { Controller } from 'stimulus';
import { get } from '@rails/request.js';

export default class extends Controller {
    static targets = ['container']
    change(event) {
        let show = event.srcElement.value == 'true';
        let classList = this.containerTarget.classList;
        if (show) {
            classList.add('block');
            classList.remove('hidden')
        } else {
            classList.remove('block');
            classList.add('hidden')
        }
    }
}